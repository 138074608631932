import React from 'react';
import {connect} from 'react-redux';
import {push} from 'react-router-redux';

import { types } from '../../constants/constants';

import InputText from '../../components/input';
import Header from '../../components/header';
import Menu from "../../components/menu";

import {getProposal,
			deleteProposal,
			sendProposalOfficial,
			closeProposal,
			changeCouponStatus,
			getTools,
			getSessions,
			getCoachesCategories,
			inputFieldChanged,
			updateInvoice,
			addCoachee,
			deleteCoachee,
			coacheeRateEmail } from '../../actions/app.js';

import { isLogged } from '../../actions/admin-users.js';

import { Checkbox } from 'antd';

import { CheckCircleOutlined,
			MailOutlined,
			DeleteOutlined,
			ExclamationCircleOutlined,
			EuroCircleOutlined,
			UploadOutlined,
			FilePdfOutlined,
			UserAddOutlined,
			CloseOutlined } from '@ant-design/icons';

import { Modal, Button, Space, Avatar } from 'antd';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from 'react-loader-spinner';

import { withTranslation } from 'react-i18next';

const { confirm } = Modal;

class ProposalView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
        	isLoaded : false,
        	formCoachee : {},
        	company_profile_email : ''
        };
    }

    componentDidMount(){

    	Promise.resolve(this.props.isLogged())
        .then((response)=> {

            if(this.props.isLoggedUser && this.props.user.role.code === 0){
    	
    			Promise.resolve(this._getDataFromAPI())
    			.then((response) => {
    				
    			})

    		} else {

    			sessionStorage.setItem('islinked', window.location.pathname);
                this.props.history.push('/login')

    		}

    	});

    }

    _getDataFromAPI(){
    	
    	// Obtener las Tools
    	Promise.resolve(this.props.getTools())
	    .then((response)=> {
	        if(this.props.dataError === false){
	            
	            // Obtener las Sessions
	            Promise.resolve(this.props.getSessions())
			    .then((response)=> {
			    	if(this.props.dataError === false){

				    	// Obtener las Coach categories
		            	Promise.resolve(this.props.getCoachesCategories())
				    	.then((response)=> {
				        
					        if(this.props.dataError === false){
					        	
					        	let proposal_id = typeof this.props.match.params.id != 'undefined' ? this.props.match.params.id : null;

					        	Promise.resolve(this.props.getProposal(proposal_id))
							    .then((response)=> {
							        if(this.props.dataError == false){
									    if(typeof this.props.proposal === 'undefined' || this.props.proposal === null){
								    		this.props.history.push('/MMP/')
								    	}else{
								    		// Comprobamos si tienen perfil de empresa activo en MVP
								    		var formBody = [];
								    		var Promises = [];
        
					                        var details={ 
					                            'email' : this.props.proposal.email
					                        };

					                        for (var property in details) {
					                              var encodedKey = encodeURIComponent(property);
					                              var encodedValue = encodeURIComponent(details[property]);
					                              formBody.push(encodedKey + "=" + encodedValue);
					                        }

					                        var status = null;

					                        Promises.push(fetch(types.API_URL + 'user/get-by-email', {
					                            method: 'post',
					                            headers: { "Content-Type": "application/x-www-form-urlencoded" },
					                            body: formBody.join("&"),
					                        })
					                        .then(function(response) {
					                            status = response.status; // Get HTTP status code
					                            return response.json();
					                        })
					                        .then((responseJson, response) => {
					                            if(status === 200){ // If success
					                            	// Solo si el usuario con este email es empresa
					                            	if(responseJson.user.role.indexOf('5ee5ff775b622f72ef2b5421') != -1){
					                                	this.setState({
															'company_profile_email' : responseJson.user.email
														});
					                                }
					                            }else{
					                            	console.log(responseJson); 
					                            }
					                        })
					                        .catch((error) => {
					                            alert(error);
					                        }));

					                        Promise.all(Promises).then(values => { 
					                        	let formCoachee = {};

								    			if(typeof this.props.proposal.programs != 'undefined'){
								    				JSON.parse(this.props.proposal.programs).map((program, key) => {
														formCoachee[key] = false; 
														this.setState({ formCoachee : formCoachee })
								    				})
								    			}

							                    this.setState({
													'isLoaded' : true
												});
							                }).catch(reason => { 
							                      console.log(reason)
							                });
								    	}
							        }
							    });
						    }

					    })

					}

			    })

	        }
	    });

    }

    _getNextCategory(category){
    	
    	let new_category = '';

    	switch (category) {
			case 'PRO-BONO':
				return 'D';
			    break;
			case 'D':
			    return 'C';
			    break;
			case 'C':
			    return 'B';
			    break;
			case 'B':
			    return 'A';
			    break;
			case 'A':
			    return 'PLUS';
			    break;
			case 'PLUS':
			    return 'PREMIUM';
			    break;
			case 'PREMIUM':
			    return 'TOP';
			    break;
			default:
			    return 'PRO-BONO';
		}

		return new_category;

    }

    _calculateDiscount(proposal){

		let totalDescuento = 0;
    	
    	JSON.parse(proposal.programs).map((program, key) => {
    		if (typeof program.sesiones != 'undefined' && program.sesiones.length > 0){
    			program.sesiones.map((sesion, key) => {
	    			if((sesion.sesion.indexOf('Devoluci') == -1 && sesion.sesion.indexOf('Herram') == -1) || (sesion.sesion.indexOf('Tool Devolution') == -1)){
	    				if( sesion.categoryCoach === 'A' || sesion.categoryCoach === 'B' || sesion.categoryCoach === 'C' || sesion.categoryCoach === 'D' ){
	    					totalDescuento = totalDescuento + sesion.totalSesion;
	    				}
	    			}
    			})
    		}
    	})

    	return (totalDescuento * 0.3).toFixed();

    }

    _activateCompanyProfile(){
    	const { t } = this.props;

    	var formBody = [];
            
        var details={ 
            'first_name' : this.props.proposal.fullname,
            'email' : this.props.proposal.email,
            'company' : this.props.proposal.razonFiscal,
            'cif' : this.props.proposal.cif,
            'department' : this.props.proposal.department,
            'position' : this.props.proposal.position,
            'phone' : this.props.proposal.phone,
            'mobile' : this.props.proposal.mobile,
            'city' : this.props.proposal.city,
            'country' : this.props.proposal.country
        };

        for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
        }

        var status = null;
        const token = localStorage.getItem('token');

        fetch(types.API_URL + 'proposal/add-company-profile', {
            method: 'post',
            headers: { 
                "Content-Type": "application/x-www-form-urlencoded",
                "token": token  
            },
            body: formBody.join("&"),
        })
        .then(function(response) {
            status = response.status; // Get HTTP status code
            return response.json();
        })
        .then((responseJson, response) => {
            if(status === 200){ // If success
            	Modal.info({
				    title: t('Perfil de empresa añadido'),
				    content: t('El perfil de empresa se ha creado, y ya está disponible en el panel de control')
				});
                
                this.setState({
					'company_profile_email' : responseJson.user.email
				});

            }else{
                Modal.error({
				    title: t('Ha ocurrido un error'),
				    content: responseJson.message
				});
            }
        })
        .catch((error) => {
            console.log(error);
        });
    }

    _saveDataCoachee(program_key, coachCategory){
    	const { t } = this.props;

    	Promise.resolve(this.props.addCoachee(
    		this.props.proposal._id, 
    		this.props.coachee_first_name, 
    		this.props.coachee_last_name, 
    		this.props.coachee_email, 
    		this.props.proposal.cif, 
    		this.props.coachee_department, 
    		this.props.coachee_position, 
    		this.props.coachee_phone, 
    		this.props.coachee_mobile, 
    		this.props.coachee_city, 
    		this.props.coachee_country,
    		program_key,
    		coachCategory))
    	.then((response)=> {
        	if(this.props.dataError === false){
	            Modal.info({
				    title: t('Coachee añadido'),
				    content: t('El coachee se ha vinculado a esta propuesta, y ya está disponible en el panel de control')
				});

				let formCoachee = {};
				formCoachee[program_key] = false;
				this.setState({'formCoachee' : formCoachee});
	        }else{
	        	if(typeof this.props.dataErrorMessage != 'undefined' && typeof this.props.dataErrorMessage.message != 'undefined'){
		        	Modal.error({
					    title: t('Ha ocurrido un error'),
					    content: 'Error: ' + this.props.dataErrorMessage.message.message
					});
		        }
	        }

    	});
    }

    _deleteCoachee(coachee_id){
    	const { t } = this.props;

    	confirm({
		    title: t('¿Seguro que quieres desvincular este coachee de la solicitud?'),
		    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
		    content: t('Si lo eliminas ya no podrá completar el programa'),
		    okText: t('Desvincular'),
		    cancelText: t('Cancelar'),
		    onOk : () => {

		    	Promise.resolve(this.props.deleteCoachee(this.props.proposal._id, coachee_id))
		        .then((response)=> {
		    	});
				
		    },
		    onCancel() {
		    	
		    },
		});
    }

    _coacheeRateEmail(coachee_id, coach_email){
    	const { t } = this.props;

    	console.log(coach_email)

    	if(typeof coach_email == 'undefined' || coach_email == ''){
    		Modal.error({
			    title: t('No ha completado el programa'),
			    content: 'Este Coachee todavía no ha seleccionado a su Coach, por lo tanto no puede valorar'
			});
    	}else{

	    	confirm({
			    title: t('¿Seguro que quieres enviar el email de valoración?'),
			    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
			    content: t('Si aceptas se enviará un email al Coachee para que haga la valoración del Coach que ha realizado el programa'),
			    okText: t('Enviar'),
			    cancelText: t('Cancelar'),
			    onOk : () => {

			    	Promise.resolve(this.props.coacheeRateEmail(this.props.proposal._id, coachee_id, coach_email))
			        .then((response)=> {
			        	if(this.props.dataError === false){
				            Modal.info({
							    title: t('Email de valoración enviado'),
							    content: t('Se ha enviado un email al Coachee para que valore a su Coach en este programa')
							});
				        }else{
				        	if(typeof this.props.dataErrorMessage != 'undefined' && typeof this.props.dataErrorMessage.message != 'undefined'){
					        	Modal.error({
								    title: t('Ha ocurrido un error'),
								    content: 'Error: ' + this.props.dataErrorMessage.message.message
								});
					        }
				        }
			    	});
					
			    },
			    onCancel() {
			    	
			    },
			})
		}
    }

    _confirmDelete(proposal_id){
    	const { t } = this.props;

    	confirm({
		    title: t('¿Seguro que quieres borrar esta solicitud?'),
		    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
		    content: t('Si lo eliminas perderás toda la información para siempre.'),
		    okText: t('Borrar'),
		    cancelText: t('Cancelar'),
		    onOk : () => {

		    	Promise.resolve(this.props.deleteProposal(this.props.proposal._id))
		        .then((response)=> {

		            this.props.history.push('/solicitud/listado');

		    	});
				
		    },
		    onCancel() {
		    	
		    },
		});
    }

    _sendProposalOfficial(proposal_id){
    	const { t } = this.props;

    	Promise.resolve(this.props.sendProposalOfficial(this.props.proposal._id))
        .then((response)=> {

        	if(this.props.dataError == false){
	        	// Mostrar modal informado del envio
	        	Modal.info({
				    title: t('Se ha enviado el email de propuesta oficial al cliente'),
				    content: t('Ahora el cliente puede revisar la propuesta oficial y confirmarla') + '. ' + t('Exekutive Coaching recibirá una notificación por email cuando el cliente confirme la propuesta'),
				    okText: t('Cerrar'),
				});
			}else{
				Modal.error({
				    title: t('Hubo un problema'),
				    content: 'Error: ' + this.props.dataErrorMessage,
				});
			}

    	});
    }

    _closeProposal(proposal_id){
    	const { t } = this.props;

    	confirm({
		    title: t('¿Seguro que quieres cerrar esta propuesta?'),
		    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
		    content: t('Esto significa que el cliente ya ha pagado o ha confirmado la recepción de la factura a 45 días y se le va a enviar el email con el documento para comenzar el programa'),
		    okText: t(t('Confirmar')),
		    onOk : () => {

		    	Promise.resolve(this.props.closeProposal(this.props.proposal._id))
		        .then((response)=> {

		        	if(this.props.dataError === false){
			            Modal.info({
						    title: t('Propuesta cerrada'),
						    content: t('Hemos enviado el email al cliente con el documento para comenzar el programa')
						});
			        }else{
			        	Modal.error({
						    title: t('Ha ocurrido un error'),
						    content: 'Error: ' + this.props.dataErrorMessage
						});
			        }

		    	});
				
		    },
		    onCancel() {
		    	
		    },
		});
    }

    _reCloseProposal(proposal_id){
    	const { t } = this.props;

    	confirm({
		    title: t('¿Seguro que quieres volver a enviarle el email al cliente?'),
		    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
		    content: t('Se le va a enviar el email con el documento para comenzar el programa.'),
		    okText: t('Confirmar'),
		    onOk : () => {

		    	Promise.resolve(this.props.closeProposal(this.props.proposal._id))
		        .then((response)=> {

		            if(this.props.dataError === false){
			            Modal.info({
						    title: t('Propuesta cerrada'),
						    content: t('Hemos enviado el email al cliente con el documento para comenzar el programa')
						});
			        }else{
			        	Modal.error({
						    title: t('Ha ocurrido un error'),
						    content: 'Error: ' + this.props.dataErrorMessage
						});
			        }

		    	});
				
		    },
		    onCancel() {
		    	
		    },
		});
    }

    _changeCouponStatus(coupon_id){
    	const { t } = this.props;

    	let title = this.props.proposal.coupon.status.code == 0 || this.props.proposal.coupon.status.code == 2 ? t('¿Seguro que quieres activar el cheque descuento?') : t('¿Seguro que quieres desactivar el cheque descuento?');
    	let content = this.props.proposal.coupon.status.code == 0 || this.props.proposal.coupon.status.code == 2 ? 'Esto significa que ya podrás enviarle el código al cliente para que pueda incluirlo en la herramienta MMP. Este código queda desactivado tan pronto como se utilice una vez o se vuelva a desactivar.' : 'Esto significa que ya no se podrá utilizar en la herramienta MMP.';
    	let titleResponse = this.props.proposal.coupon.status.code == 0 || this.props.proposal.coupon.status.code == 2 ? t('Cheque descuento activado') : t('Cheque descuento desactivado');
    	let contentResponse = this.props.proposal.coupon.status.code == 0 || this.props.proposal.coupon.status.code == 2 ? t('El cheque descuento está activado') : t('El cheque descuento está desactivado');


    	confirm({
		    title: title,
		    icon: <ExclamationCircleOutlined style={{ color: '#FF866B' }} />,
		    content: content,
		    okText: t('Confirmar'),
		    onOk : () => {

		    	Promise.resolve(this.props.changeCouponStatus(coupon_id))
		        .then((response)=> {

		        	if(this.props.dataError === false){
			            Modal.info({
						    title: titleResponse,
						    content: contentResponse
						});
			        }else{
			        	Modal.error({
						    title: t('Ha ocurrido un error'),
						    content: 'Error: ' + this.props.dataErrorMessage
						});
			        }

		    	});
				
		    },
		    onCancel() {
		    	
		    },
		});
    }

    _uploadInvoice=event=>{
    	const { t } = this.props;

    	var data = new FormData()
		data.append('folder', 'invoices')
		data.append('file', event.target.files[0])		

		fetch(types.API_URL + 'upload', {
		  	method: 'POST',
		  	body: data
		})
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                   	alert('Error subiendo el fichero')
                }
            }
        })
        .then((responseJson) => {
        	Promise.resolve(this.props.updateInvoice(this.props.proposal._id, responseJson))
        	.then((response)=> {
	        	if(this.props.dataError === false){
		            Modal.info({
					    title: t('Factura guardada'),
					    content: t('Ya se ha guardado la factura y esta disponible para su consulta')
					});
		        }else{
		        	Modal.error({
					    title: t('Ha ocurrido un error'),
					    content: 'Error: ' + this.props.dataErrorMessage
					});
		        }

	    	});
        })
        .catch((error) => {
            alert(error)
        });
    }

    _deleteInvoice(){
    	const { t } = this.props;

    	var formBody = [];
            
        var details={
            'file' : this.props.proposal.invoice,
        };

        for (var property in details) {
              var encodedKey = encodeURIComponent(property);
              var encodedValue = encodeURIComponent(details[property]);
              formBody.push(encodedKey + "=" + encodedValue);
        }	

		const token = localStorage.getItem('token');

		fetch(types.API_URL + 'delete-file', {
		  	method: 'POST',
		  	headers: { 
		  		"Content-Type": "application/x-www-form-urlencoded",
                'token': token 
            },
		  	body: formBody.join("&")
		})
        .then(function(response) {
            if (response.status === 404 || response.status === 200) {
                return response.json()
            } else {
                if(response.status === 401){  // If unauthorized
                    window.location.href = '/login'; 
                }else{
                   	alert('Error subiendo el fichero')
                }
            }
        })
        .then((responseJson) => {
        	Promise.resolve(this.props.updateInvoice(this.props.proposal._id, ''))
        	.then((response)=> {
	        	if(this.props.dataError === false){
		            Modal.info({
					    title: t('Factura eliminada'),
					    content: 'Se ha borrado la factura de forma permanente. Ya no está accesible'
					});
		        }else{
		        	Modal.error({
					    title: t('Ha ocurrido un error'),
					    content: 'Error: ' + this.props.dataErrorMessage
					});
		        }

	    	});
        })
        .catch((error) => {
            alert(error)
        });
    }

	_renderLoader () {
        return(
            <div className={"row pt-5"}>
                <div className={"col-12 text-center"}>
                    <Loader
                        type="ThreeDots"
                        color="#FF866B"
                        height={60}
                        width={60}
                    />
                </div>
            </div>
        );
    }

    _renderContact(){
    	const { t } = this.props;
    	
    	const proposal = this.props.proposal;

    	return(

			<React.Fragment>

		    	<div className={'row'}>
					<div className={'col-md-12'}>
						<h2 className={'mb-4'}>{t('Datos de contacto')}</h2>
					</div>
				</div>

				<div className={'row'}>
					{ proposal.status.code == 3 && ( // Propuesta cerrada
						<React.Fragment>
							<div className={'col-12'}>
								<p>
									<b>{t('Email de acceso a MVP')}: </b>
									{(typeof this.state.company_profile_email != 'undefined' && this.state.company_profile_email != '') && (
					        			<span className={'pl-1'}>{ this.state.company_profile_email }</span>
				                    )}

				                    {(typeof this.state.company_profile_email == 'undefined' || this.state.company_profile_email == '') && (
				                    	<React.Fragment>
					        			<button 
				                    		className="btn d-inline-flex fs-08 align-items-center justify-content-center ml-1"
				                    		onClick={ this._activateCompanyProfile.bind(this) }>
				                      		{t('Activar perfil de empresa')}{" "}
				                      		<UserAddOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
				                    	</button><span className={'fs-08 pl-3'}>{" "} {t('Hasta que no lo actives no podrás añadir Coachees a la propuesta')}</span>
				                    	</React.Fragment>
				                    )}
								</p>
							</div>
						</React.Fragment>
					)}

					<div className={'col-md-6'}>
						<p><b>{t('Nombre')}: </b> { proposal.fullname != 'undefined' ? proposal.fullname : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Email')}: </b> { proposal.email != 'undefined' ? proposal.email : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Teléfono')}: </b> { proposal.phone !== 'undefined' ? proposal.phone : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Móvil')}: </b> { proposal.mobile != 'undefined' ? proposal.mobile : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Ciudad')}: </b> { proposal.city != 'undefined' ? proposal.city : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('País')}: </b> { proposal.country != 'undefined' ? proposal.country : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Empresa')}: </b> { proposal.company != 'undefined' ? proposal.company : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Departamento')}: </b> { proposal.department != 'undefined' ? proposal.department : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Posicion')}: </b> { proposal.position != 'undefined' ? proposal.position : '-'} </p>
					</div>
				</div>

			</React.Fragment>

		);
    }

    _renderFiscal(){
    	const { t } = this.props;

    	const proposal = this.props.proposal;

    	return(

			<React.Fragment>

		    	<div className={'row mt-3'}>
					<div className={'col-md-12'}>
						<h2 className={'mb-4'}>{t('Datos fiscales')}</h2>
					</div>
				</div>

				<div className={'row'}>
					<div className={'col-md-6'}>
						<p><b>{t('Razón fiscal')}: </b> { proposal.razonFiscal != 'undefined' ? proposal.razonFiscal : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('CIF/NIF')}: </b> { proposal.cif != 'undefined' ? proposal.cif : '-'} </p>
					</div>
					<div className={'col-md-12'}>
						<p><b>{t('Dirección')}: </b> { proposal.direccionFacturacion !== 'undefined' ? proposal.direccionFacturacion : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Provincia')}: </b> { proposal.provinciaFacturacion != 'undefined' ? proposal.provinciaFacturacion : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Ciudad')}: </b> { proposal.ciudadFacturacion != 'undefined' ? proposal.ciudadFacturacion : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>Cod. postal: </b> { proposal.codigopostalFacturacion != 'undefined' ? proposal.codigopostalFacturacion : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Teléfono')}: </b> { proposal.telefonoFacturacion != 'undefined' ? proposal.telefonoFacturacion : '-'} </p>
					</div>
					<div className={'col-md-6'}>
						<p><b>{t('Modo de pago')}: </b> { proposal.pago != 'undefined' ? proposal.pago : '-'} </p>
					</div>
				</div>

			</React.Fragment>

		);
    }

    _renderCoachees(program_key, coachCategory){
    	const { t } = this.props;

    	const proposal = this.props.proposal;

    	let formCoachee = this.state.formCoachee;

    	let maxCoacheesNumber = JSON.parse(this.props.proposal.programs)[program_key].coacheesNumber;

    	return(

			<React.Fragment>

				<div className={'row border-bottom pb-4 mb-4 align-items-center'}>

					<div className={'col-12 col-md-4'}>
						<p className={'m-0'}><b>{t('Listado de coachees')}</b></p>
					</div>

					<div className={'col-12 col-md-4'}>
						{ this.state.formCoachee[program_key] == false && proposal.coachees.length < maxCoacheesNumber &&  (
							<button 
			        		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			        		onClick={() => { formCoachee[program_key] = true; this.setState({ formCoachee : formCoachee })} }>
		                      	{t('Añadir coachee')}
		                        <UserAddOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
		                    </button>
		                )}

		                { this.state.formCoachee[program_key] && (
							<button 
			        		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			        		onClick={() => { formCoachee[program_key] = false; this.setState({ formCoachee : formCoachee })} }>
		                      	{t('Cerrar formulario')}
		                        <CloseOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
		                    </button>
		                )}
					</div>


					{ this.state.formCoachee[program_key] && (
		        		<React.Fragment>
		        			<div className={'col-12'}>

								<div className={'row py-4 px-5 mt-3'}>
									<div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_email'}
			                                name={'coachee_email'}
			                                placeholder={t('Email')}
			                                classes={'w-100'}
			                                label={t('Email')}
			                                value={this.props.coachee_email}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_email} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_company_name'}
			                                name={'coachee_company_name'}
			                                placeholder={''}
			                                classes={'w-100'}
			                                label={''}
			                                value={this.props.proposal.razonFiscal}
			                                disabled />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_first_name'}
			                                name={'coachee_first_name'}
			                                placeholder={t('Nombre')}
			                                classes={'w-100'}
			                                label={t('Nombre')}
			                                value={this.props.coachee_first_name}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_first_name} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_last_name'}
			                                name={'coachee_last_name'}
			                                placeholder={t('Apellido')}
			                                classes={'w-100'}
			                                label={t('Apellido')}
			                                value={this.props.coachee_last_name}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_last_name} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_department'}
			                                name={'coachee_department'}
			                                placeholder={t('Departamento')}
			                                classes={'w-100'}
			                                label={t('Departamento')}
			                                value={this.props.coachee_department}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_department} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_position'}
			                                name={'coachee_position'}
			                                placeholder={t('Puesto')}
			                                classes={'w-100'}
			                                label={t('Puesto')}
			                                value={this.props.coachee_position}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_position} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_phone'}
			                                name={'coachee_phone'}
			                                placeholder={t('Teléfono')}
			                                classes={'w-100'}
			                                label={t('Teléfono')}
			                                value={this.props.coachee_phone}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_phone} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_mobile'}
			                                name={'coachee_mobile'}
			                                placeholder={t('Móvil')}
			                                classes={'w-100'}
			                                label={t('Móvil')}
			                                value={this.props.coachee_mobile}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_mobile} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_city'}
			                                name={'coachee_city'}
			                                placeholder={t('Ciudad')}
			                                classes={'w-100'}
			                                label={t('Ciudad')}
			                                value={this.props.coachee_city}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_city} />
			                        </div>

			                        <div className={'col-12 col-md-6 mb-4'}>
			                            <InputText 
			                                id={'coachee_country'}
			                                name={'coachee_country'}
			                                placeholder={t('País')}
			                                classes={'w-100'}
			                                label={t('País')}
			                                value={this.props.coachee_country}
			                                onChange={this.props.inputFieldChanged.bind(this)}
			                                error={this.props.dataErrorMessage.coachee_country} />
			                        </div>

			                    </div>

			                    <div className={'row px-5'}>
				                    <div className={'col-12 text-right'}>
				                        <a className={'btn-submit mt-0'} onClick={this._saveDataCoachee.bind(this, program_key, coachCategory)}>
				                            <span className={'circle'} aria-hidden="true">
				                                  <span className={'icon arrow'}></span>
				                            </span>
				                            <span className={'button-text'}>{t('Guardar')}</span></a>
				                    </div>
				                </div>

				            </div>
			            </React.Fragment>
			        )}

					
        			<div className={'col-12 pt-3'}>

        				{(typeof proposal.coachees != 'undefined' && proposal.coachees) ? 
										
							proposal.coachees.map((coachee, key) => {

								let coachee_id = coachee.user._id;
								let coach_email = typeof coachee.coach == 'undefined' || coachee.coach == null ? '' : coachee.coach.email;

								return(
									<React.Fragment key={key}>
									{(coachee.coachCategory == coachCategory && coachee.program == program_key) && (
										<div className={'row row-flex align-items-center py-2'}>

											<div  className={'col-12'}>
												<a onClick={ this._deleteCoachee.bind(this, coachee.user._id) } 
													title={t('Desvincular al Coachee')}>
														<DeleteOutlined 
														style={{ color: '#FF866B', marginTop: '-0.2em', marginRight: '0.5em', verticalAlign: '0.9em' }} />
												</a>

												<a onClick={ this._coacheeRateEmail.bind(this, coachee.user._id, coach_email) } 
													title={t('Enviar el email de valoración del Coach')}>
														<MailOutlined 
														style={{ color: '#FF866B', marginTop: '-0.2em', marginRight: '0.5em', verticalAlign: '0.9em' }} />
												</a>

												<Avatar size={45} style={{ color: '#463B34', backgroundColor: '#F5F3EF', marginRight: '0.5em', verticalAlign: '-0.3em' }}>
													{ coachee.user.first_name[0].toUpperCase() }
													{ coachee.user.last_name[0].toUpperCase() }
												</Avatar>

												<p className={'d-inline-block m-0'}>
													{ coachee.user.first_name[0].toUpperCase() +  coachee.user.first_name.slice(1) }{' '}
													{ coachee.user.last_name[0].toUpperCase() +  coachee.user.last_name.slice(1) }<br />
													<b>{ coachee.user.email }</b>
												</p>
											</div>

										</div>
									)}
									</React.Fragment>
								)
							})
						:  <p className={'mt-4'}>{t('No hay ningún coachee para esta propuesta')}</p>

	            		}

		            </div>

				</div>

			</React.Fragment>

		);
    }

	_renderProposal(){

		const { t } = this.props;

		const proposal = this.props.proposal;

		let totalPropuesta = 0;

		var formatter = new Intl.NumberFormat('de-DE', {
		  	style: 'currency',
		  	currency: 'EUR',
		});

		let totalDescuento = parseInt(this._calculateDiscount(proposal));

		return(

			<React.Fragment>
				{/* CHEQUE DESCUENTO */ }
				{ typeof proposal.coupon != 'undefined' && (
			        <div className={'row pb-3 mb-3 align-items-center'}>
			        	<div className={'col-12 col-md-8 text-left mb-3'}>
			        		<p className={'mb-0'}><b>{t('CHEQUE DESCUENTO')}:</b> { proposal.coupon.code } - { proposal.coupon.status.message } ({ formatter.format(proposal.coupon.value) })</p>
			        	</div>

			        	<div className={'col-12 col-md-4 text-right mb-3'}>
			        		<button 
			        		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			        		onClick={this._changeCouponStatus.bind(this, proposal.coupon._id)}
			        		>
		                      	{ proposal.coupon.status.code == 0 ? 'Activar cheque' : null }
		                      	{ proposal.coupon.status.code == 1 ? 'Desactivar cheque'  : null }
		                        <EuroCircleOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
		                    </button>
		            	</div>
			        </div>
			    )}

				{/* ESTADO DE LA PROPUESTA */ }
				<div className={'row border-bottom pb-4 mb-4 align-items-center'}>
		        	<div className={'col-12 col-md-4 text-left mb-3'}>
		        		<p className={'mb-0'}><b>{t('ESTADO')}:</b> { t(proposal.status.message) }</p>
		        	</div>

		        	<div className={'col-12 col-md-4 text-center mb-3'}>
		        		{ (proposal.status.code == 0 ||  proposal.status.code == 1) && ( // Es propuesta creada o propuesta oficial
			        		<button 
			        		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			        		onClick={this._sendProposalOfficial.bind(this, proposal._id)}
			        		>
		                      	{t('Enviar propuesta oficial')}{" "}
		                        <MailOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
		                    </button>
		                )}

		                { proposal.status.code == 2 && ( // Es Pedido
			        		<button 
			        		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			        		onClick={this._closeProposal.bind(this, proposal._id)}
			        		>
		                      	{t('Cerrar propuesta')}{" "}
		                        <MailOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
		                    </button>
		                )}

		                { proposal.status.code == 3 && ( // Propuesta cerrada
			        		<button 
			        		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			        		onClick={this._reCloseProposal.bind(this, proposal._id)}
			        		>
		                      	{t('Reenviar email pago')}{" "}
		                        <MailOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
		                    </button>
		                )}
	                </div>

	                <div className={'col-12 col-md-4 text-center mb-3'}>
	                    <button 
	                    	className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
	                    	onClick={this._confirmDelete.bind(this, proposal._id)}>
	                      	{t('Eliminar solicitud')}{" "}
	                        <DeleteOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
	                    </button>
		        	</div>
		        </div>

				{/* FACTURA DE LA PROPUESTA */ }
				{ proposal.status.code == 3 && ( // Propuesta cerrada
					<React.Fragment>
						<div className={'row border-bottom pb-2 mb-4 align-items-center'}>
				        	<div className={'col-12 col-md text-left mb-3'}>
				        		<b>{t('Factura')}:</b>
				        		{(typeof proposal.invoice != 'undefined' && proposal.invoice != '') && (
				        			<a href={ proposal.invoice } target="_blank" className={'pl-1'}>
				        				{ proposal.invoice.substring(proposal.invoice.lastIndexOf('/')+1) }
				        			</a>
			                    )}

			                    {(typeof proposal.invoice == 'undefined' || proposal.invoice == '') && (
				        			<span className={'pl-1'}>No existe factura</span>
			                    )}

			                </div>

			                <div className={'col-12 col-md text-left mb-3'}>
			        			<React.Fragment>
				        			<input type="file" ref={input => this.invoice = input} className={'d-none'} onChange={this._uploadInvoice.bind(this)}/>
			                    	<button 
			                    		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
			                    		onClick={ ()=>{this.invoice.click()} }>
			                      		{t('Subir factura')}{" "}
			                      		<UploadOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
			                    	</button>
			                    </React.Fragment>
				        	</div>

					        <div className={'col-12 col-md text-left mb-3'}>
					        	{ (typeof proposal.invoice != 'undefined' && proposal.invoice != '') && (
				        			<React.Fragment>
				                    	<button 
				                    		className="btn w-100 fs-08 d-flex align-items-center justify-content-center"
				                    		onClick={ this._deleteInvoice.bind(this) }>
				                      		{t('Eliminar factura')}{" "}
				                      		<DeleteOutlined style={{ color: '#FF866B', fontSize: '1.4em', marginLeft: '0.7em' }} />
				                    	</button>
				                    </React.Fragment>
				                )}
					        </div>
				        </div>
				    </React.Fragment>
				)}

				{/* DATOS DE CONTACTO */ }
				
				{ this._renderContact() }

				{/* DATOS FISCALES */ }

				{ 
					proposal.status.code >= 2 ?
						this._renderFiscal()
					: null
				}

				{/* DESGLOSE DE LA PROPUESTA */ }
				<div className={'row mt-4'}>
					<div className={'col-md-12'}>
						<h2 className={'mb-4'}>{t('Desglose de la propuesta')}</h2>
					</div>
				</div>

				{
					JSON.parse(proposal.programs).map((program, key) => {

						return(
							<div className={'row checkout-row'} key={ key }>

								<div className={'col-12'}>
									
									<p className={'fs-12'}><b>Programa: </b><span className={'orange'}>{ program.programName }</span></p>
								
								</div>
								
								<div className={'col-sm-6'}>

									<p><b>{t('Nº Coachees')}: </b><span>{ program.coacheesNumber }</span></p>

								</div>

								<div className={'col-sm-6'}>

									<p><b>{t('Categoría del Coach')}: </b><span>{ program.coachCategory }</span></p>

								</div>

								<div className={'col-12'}>
									{/* COACHEES */ }

									{ 
										proposal.status.code >= 3 && (typeof this.state.company_profile_email != 'undefined' && this.state.company_profile_email != '') ?
											this._renderCoachees(key, program.coachCategory)
										: null
									}
								</div>

								<div className={'col-12 pb-4 pt-3'}>

									<p className={'fs-12 mb-1'}><b>{t('Herramientas')}</b></p>

	  							{
	  								program.herramientas ? 
										
										program.herramientas.map((herramienta, key) => {

											return(

												<React.Fragment key={key}>

												<div className={'row'}>

													<div className={'col-5 col-sm-6 py-2 fs-09'}>{ herramienta.herramienta }</div>

													<div className={'col-1 col-sm-1 py-2 fs-09'}>x{ herramienta.numero }</div>

													<div className={'col-1 col-sm-3 py-2 fs-09'}>{ herramienta.categoryCoach }</div>

													<div className={'col-4 col-sm-2 py-2 fs-09 text-right'}> { formatter.format(herramienta.totalHerramienta) }</div>

												</div>

												</React.Fragment>
											);
										})

										:  <p>{t('No hay herramientas contratadas')}</p>

	            				}

								</div>

								<div className={'col-12 mb-3'}>

									<p className={'fs-12 mb-1'}><b>{t('Sesiones')}</b></p>

	  							{
										program.sesiones.map((sesion, key) => {
											if(sesion.cantidadPorCoachee > 0){

												if((sesion.sesion.indexOf('Devoluci') == -1 && sesion.sesion.indexOf('Herram') == -1) && (sesion.sesion.indexOf('Tool Devolution') == -1)){
			  										return(

			  											<React.Fragment key={key}>

			  											<div className={'row'}>

			  												<div className={'col-5 col-sm-6 py-2 fs-09'}>{ sesion.sesion }</div>

			  												<div className={'col-1 col-sm-1 py-2 fs-09'}>x{ sesion.cantidadPorCoachee }</div>

			  												<div className={'col-1 col-sm-3 py-2 fs-09'}> { sesion.categoryCoach }</div>

			  												<div className={'col-4 col-sm-2 py-2 fs-09 text-right'}> { formatter.format(sesion.totalSesion) }</div>

			  											</div>

			  											</React.Fragment>
			  										);
			  									}else{
			  										let numSesionesDevolucion = 0;

			  										if(program.herramientas){ 
										
														program.herramientas.map((herramienta, key) => {
															numSesionesDevolucion = parseInt(numSesionesDevolucion) + parseInt(herramienta.numero);
														})

														return(

				  											<React.Fragment key={key}>

				  											<div className={'row'}>

				  												<div className={'col-5 col-sm-6 py-2 fs-09'}>{ sesion.sesion }</div>

				  												<div className={'col-1 col-sm-1 py-2 fs-09'}>x{ numSesionesDevolucion }</div>

				  												<div className={'col-1 col-sm-3 py-2 fs-09'}> { sesion.categoryCoach }</div>

				  												<div className={'col-4 col-sm-2 py-2 fs-09 text-right'}> { formatter.format(sesion.totalSesion) }</div>

				  											</div>

				  											</React.Fragment>
				  										);

													}
			  									}
	  										}
										})
	            				}

								</div>

								<div className={'col-md-12 fs-12 text-right py-1'}><b>{t('Total programa')}:</b> { formatter.format(program.totalPrograma) }</div>

							</div>
						);
					})
	        	}

	        	{ typeof proposal.couponValidated != 'undefined' && (

	        		<React.Fragment>
			        	<div className={'row mt-4'}>
							<div className={'col-md-12'}>
								<h2 className={'mb-4'}>{t('Cheque descuento aplicado')}</h2>
							</div>
						</div>

						<div className={'row checkout-row'}>

							<div className={'col-12 col-md-6'}>
								
								<p><b>{t('Código')}: </b><span>{ proposal.couponValidated.code }</span></p>
							
							</div>

							<div className={ 'col-12 col-md-6 text-right' }>
								- { formatter.format(proposal.couponValidated.value) }
							</div>
						</div>
					</React.Fragment>
					)
				}

		        <div className={'row mt-5'}>

		        	<div className={'col-9 text-right fs-11 bold py-1'}>Subtotal</div>

		        	<div className={'col-3 text-right fs-11 py-1'}>{ formatter.format(proposal.totalPropuesta) }</div>

		        	<div className={'col-9 text-right fs-11 bold py-1'}>{t('IVA')}</div>

		        	<div className={'col-3 text-right fs-11 py-1'}>{ formatter.format(proposal.totalPropuesta * 0.21) }</div>

		        	<div className={'col-9 text-right fs-12 bold py-1'}>TOTAL</div>

		        	<div className={'col-3 text-right fs-12 bold py-1'}>{ formatter.format(proposal.totalPropuesta * 1.21) }</div>

		        </div>

		        <div className={'row mt-3'}>
		        	<div className={'col-12 text-left'}>
		        		<p className={'fs-08'}>{t('Esta oferta genera un cheque descuento de')}: { formatter.format(totalDescuento) }</p>
		        	</div>
		        </div>
		    </React.Fragment>
			      					
		);

	}

    render() {

    	const { t } = this.props;

        return(
        	<React.Fragment>
	            <div className={"split-container"}>
	                <Menu history={this.props.history} />
	                <div className={"container coachee"}>
	                    <div className={"row"}>
	                        <div className={"col-12 pt-3"}>
	                            <header className={"managementPanel__content-header"}>
	                                <div>
	                                    <h1 className={"coachee__title"}>EXEKUTIVE COACHING:</h1>
	                                    <p className={"coachee__title"}>{t('Detalles de la solicitud de propuesta')}</p>
	                                </div>

	                                <div className={"coachee__download-instructions d-none d-sm-block"}>
					                    <a className={'btn-submit mt-2'} href="/solicitud/listado">
				      						<span className={'circle'} aria-hidden="true">
				      							<span className={'icon arrow'}></span>
				      						</span>
				      						<span className={'button-text'}>{t('Volver')}</span></a>
					                </div>
	                            </header>

	                            <section>
	                            	<div className={'box-with-shadow'}>
		                                { 
		                                    this.state.isLoaded ? 
		                                        this._renderProposal()
		                                    :
		                                        this._renderLoader()
		                                }
	                                </div>

		                            <div className={'row d-block d-sm-none'}>
		                                <div className={"col-6 offset-6 text-right"}>
						                    <a className={'btn-submit mt-2'} href="/solicitud/listado">
					      						<span className={'circle'} aria-hidden="true">
					      							<span className={'icon arrow'}></span>
					      						</span>
					      						<span className={'button-text'}>{t('Volver')}</span></a>
						                </div>
						            </div>
	                            </section>
	                        </div>
	                    </div>
	                </div>
	            </div>
	        </React.Fragment>
        );

    }

}

function mapStateToProps(state) {
    const storeApp = state.app;
    const storeAdminUsers = state.adminUsers;

    return {
    	proposal : storeApp.proposal,
    	tools : storeApp.tools,
    	sessions : storeApp.sessions,
    	coachCategories: storeApp.coachCategories,
    	dataError : storeApp.dataError,
        dataErrorMessage: storeApp.dataErrorMessage,
        invoice: storeApp.invoice,
		coachee_first_name : storeApp.coachee_first_name,
		coachee_last_name : storeApp.coachee_last_name,
		coachee_email : storeApp.coachee_email,
		coachee_department : storeApp.coachee_department,
		coachee_position : storeApp.coachee_position,
		coachee_phone : storeApp.coachee_phone,
		coachee_mobile : storeApp.coachee_mobile,
		coachee_city : storeApp.coachee_city,
        coachee_country : storeApp.coachee_country,
        isLoggedUser : storeAdminUsers.isLogged,
        user: storeAdminUsers.user,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        push: redirectTo => dispatch(push(redirectTo)),
        inputFieldChanged: (field, text) => dispatch(inputFieldChanged(field, text)),
        getProposal: (id) => dispatch(getProposal(id)),
        deleteProposal: (id) => dispatch(deleteProposal(id)),
        sendProposalOfficial: (id) => dispatch(sendProposalOfficial(id)),
        closeProposal: (id) => dispatch(closeProposal(id)),
        changeCouponStatus: (id) => dispatch(changeCouponStatus(id)),
        getTools: () => dispatch(getTools()),
        getSessions: () => dispatch(getSessions()),
        getCoachesCategories: () => dispatch(getCoachesCategories()),
        updateInvoice: (proposal_id, invoice) => dispatch(updateInvoice(proposal_id, invoice)),
        addCoachee: (proposal_id, first_name, last_name, email, company, department, position, phone, mobile, city, country, program_key, coachCategory) => 
        				dispatch(addCoachee(proposal_id, first_name, last_name, email, company, department, position, phone, mobile, city, country, program_key, coachCategory)),
        deleteCoachee: (proposal_id, coachee_id) => dispatch(deleteCoachee(proposal_id, coachee_id)),
        coacheeRateEmail: (proposal_id, coachee_id) => dispatch(coacheeRateEmail(proposal_id, coachee_id)),
        isLogged: () => dispatch(isLogged())
    };
}

export default withTranslation()(connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProposalView));